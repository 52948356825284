import React, { useState } from "react";

const RedirectButtons = () => {
  const [bg, setBg] = useState("");

  const hoverHandlers = {
    Utvikling: () => setBg("bg-purple-800"),
    Videoproduksjon: () => setBg("bg-indigo-800"),
  };

  //   const innerBorderStyle = {
  //     boxShadow: "inset 0 0 0 1px white",
  //   };

  return (
    <div
      className={`h-screen flex flex-col justify-center items-center ${bg} transition-all ease-in-out duration-500`}
    >
      <button
        onMouseEnter={() => hoverHandlers.Utvikling()}
        onMouseLeave={() => setBg("")}
        onClick={() => (window.location.href = "https://www.gjrtsn.dev")}
        className="m-4 w-1/3 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-700 hover:to-indigo-600 text-white px-8 py-4 rounded-xl hover:shadow-xl transform hover:scale-105 transition-all duration-300 uppercase font-bold text-2xl"
        // style={innerBorderStyle}
      >
        Utvikling
      </button>
      <button
        onMouseEnter={() => hoverHandlers.Videoproduksjon()}
        onMouseLeave={() => setBg("")}
        onClick={() => (window.location.href = "https://www.gjrtsn.no")}
        className="m-4 w-1/3 bg-gradient-to-r from-teal-500 to-blue-500 hover:from-teal-700 hover:to-blue-800 text-white px-6 py-4 rounded-xl hover:shadow-xl transform hover:scale-105 transition-all duration-300 uppercase font-bold text-2xl"
        // style={innerBorderStyle}
      >
        Videoproduksjon
      </button>
    </div>
  );
};

export default RedirectButtons;
